$(document).ready(function () {
    url = window.location.pathname.split('/')
    console.log(url)
    if ((url[3] == 'plantations' && url[4] == 'new') || (url[1] == 'plantations' && url[3] == 'edit')) {
        var options_var =""
        console.log(url)
        // clear select
        if (url[3] == 'plantations' && url[4] == 'new'){
            document.getElementById('plantation_pi_line_plantation').value = ""
        }
        // clear select


        // Pi line -> Species
        if (document.getElementById('plantation_pi_line_plantation').value == ""){
            $('#plantation_species_plantation').parent().hide()
            $('#plantation_variety_id').parent().hide()
        }

        species = $('#plantation_species_plantation').html()
        var pi_input = document.getElementById('plantation_pi_line_plantation');

        pi_input.addEventListener('change', (event) => {
            options_var =""
            $('#plantation_variety_id').parent().hide()
            pi_line = $('#plantation_pi_line_plantation :selected').text()
            console.log("selected" + pi_line)
            options = $(species).filter('optgroup[label="' + pi_line + '"]').html()
            if (options){
                $('#plantation_species_plantation').html(options)
                $('#plantation_species_plantation').parent().show()
                document.getElementById('plantation_species_plantation').value = ""
            } else {
                $('#plantation_species_plantation').empty()
                $('#plantation_species_plantation').parent().hide()
                $('#plantation_variety_id').parent().hide()
            }
        })

        // //  Species -> varietat
        variety = $('#plantation_variety_id').html()
        var species_input = document.getElementById('plantation_species_plantation');

        species_input.addEventListener('change', (event) =>{
            species_line = $('#plantation_species_plantation :selected').text()
            console.log("selected" + species_line)
            options_var = $(variety).filter('optgroup[label="' + species_line + '"]').html()
            if (options_var){
                $('#plantation_variety_id').html(options_var)
                $('#plantation_variety_id').parent().show()
            } else {
                $('#plantation_variety_id').empty()
                $('#plantation_variety_id').parent().hide()
            }
        })
    }

});
