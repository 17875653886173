import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['messageWrapper', 'formWrapper', 'messageBtn'];

  connect() {
    this.toggleForm();
  }

  toggleForm() {
    const messageBtn = document.getElementById('new-user-form-btn');
    const messageWrapper = document.getElementById('new-user-message-wrapper');
    const formWrapper = document.getElementById('new-user-form-wrapper');

    if (messageBtn) {
      messageBtn.addEventListener('click', function() {
        messageWrapper.style.display = 'none';
        formWrapper.classList.remove('hidden');
      });
    }
  }
}

