import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  addFields(event) {
    event.preventDefault();
    
    const { element } = this;
    const container = element.querySelector('.file-fields-container');
    const addButtonContainer = element.querySelector('.add-fields-button-container');
    const newFileFields = element.querySelector('.file-fields').cloneNode(true);
  
    let maxIndex = 0;
    container.querySelectorAll('.file-fields[data-row-index]').forEach(fileField => {
      const index = parseInt(fileField.getAttribute('data-row-index'), 10);
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    
    const newIndex = maxIndex + 1;
    newFileFields.setAttribute('data-row-index', newIndex.toString());
  
    newFileFields.querySelectorAll('input[type="file"]').forEach(fileInput => {
      const inputName = fileInput.name.replace(/\[\d+\]/, `[${newIndex}]`);
      fileInput.setAttribute('name', inputName);
      fileInput.value = ""; 
    });
      
    newFileFields.querySelectorAll('[data-file-link]').forEach(downloadLink => {
      downloadLink.remove();
    });

    const deleteButton = newFileFields.querySelector('.delete-row-button');
    deleteButton.classList.remove('d-none');

    container.insertBefore(newFileFields, addButtonContainer);
  }
  
  deleteRow(event) {
    event.preventDefault();

    const fileRow = event.target.closest('.file-fields');
    
    if (fileRow) {
      const rowIndex = fileRow.getAttribute('data-row-index');
      fileRow.setAttribute('data-marked-for-deletion', 'true');
    
      const { element } = this;
      const markedRowsInput = element.querySelector('[data-marked-rows]');
      markedRowsInput.value += (markedRowsInput.value ? ',' : '') + rowIndex;
    
      fileRow.querySelectorAll('input[type="file"]').forEach(input => {
        input.disabled = true;
      });
    
      fileRow.style.display = 'none';
    }
  }
}  
