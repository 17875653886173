import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTargets.forEach((button) => {
      button.addEventListener("click", this.uploadFile.bind(this));
    });
  }

  async uploadFile(event) {
    const button = event.currentTarget;
    const { fileId, fileType, operatorId, operatorType } = button.dataset;
    const url = `/digital_registry_files/${fileId}/view`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            .getAttribute("content"),
        },
      });

      const blob = await response.blob();
      const formData = new FormData();
      formData.append("file", new File([blob], `${fileType}.pdf`, { type: blob.type }));
      formData.append("file_id", fileId);
      formData.append("file_type", fileType);

      let uploadUrl = "";
      switch (operatorType) {
        case "Productor":
          uploadUrl = `/producers/${operatorId}/upload_file`;
          break;
        case "Elaborador":
          uploadUrl = `/elaborators/${operatorId}/upload_file`;
          break;
        case "Tecnic":
          uploadUrl = `/technicians/${operatorId}/upload_file`;
          break;
      }

      const uploadResponse = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            .getAttribute("content"),
        },
        body: formData,
      });

      const data = await uploadResponse.json();

      if (data.success) {
        const img = document.createElement("img");
        img.src = "/assets/svg/check-green.svg";
        img.title = "Correcte";
        img.className = "file-search";
        button.replaceWith(img);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }
}
