document.addEventListener('turbo:load', () => {
  const dataProcessingInfo = document.getElementById('data_processing_info');
  const hiddenInfo = document.getElementById('hidden_info');

  if (dataProcessingInfo) {
    dataProcessingInfo.addEventListener('click', () => {
      hiddenInfo.classList.toggle('hidden');
    });
  }
});
