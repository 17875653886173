import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "form"];

  connect() {
    this.currentlyEditingId = null;
    this.currentlyEditingType = null;
  }

  edit(event) {
    event.preventDefault();
    
    const { itemId, itemType } = event.currentTarget.dataset;
    const isSameItemClicked = this.currentlyEditingId === itemId && this.currentlyEditingType === itemType;
  
    if (this.currentlyEditingId && !isSameItemClicked) {
      this.toggleForm(this.currentlyEditingId, this.currentlyEditingType, false);
    }

    this.toggleForm(itemId, itemType, !isSameItemClicked);
    if (isSameItemClicked) {
      this.currentlyEditingId = null;
      this.currentlyEditingType = null;
    } else {
      this.currentlyEditingId = itemId;
      this.currentlyEditingType = itemType;
    }
  }  

  toggleForm(itemId, itemType, open) {
    const nameElementId = itemType === 'land' ? `land-name-${itemId}` : `analytical-unit-name-${itemId}`;
    const formElementId = itemType === 'land' ? `edit-land-form-${itemId}` : `edit-analytical-unit-form-${itemId}`;
  
    const nameElement = this.nameTargets.find(target => target.id === nameElementId);
    const formElement = this.formTargets.find(form => form.id === formElementId);
  
    if (!nameElement || !formElement) return;

    if (open) {
      const inputField = formElement.querySelector('input[type="text"]');
      if (inputField) {
        inputField.value = nameElement.textContent || nameElement.innerText;
      }
    }

    nameElement.classList.toggle('d-none', open);
    formElement.classList.toggle('d-none', !open);
  }
}
