document.addEventListener('turbo:load', () => {
  const checkbox2 = document.getElementById('pi_course_checkbox_2');
  const checkbox3 = document.getElementById('pi_course_checkbox_3');
  const fileInput2 = document.getElementById('digital_registry_file_2');
  const fileInput3 = document.getElementById('digital_registry_file_3');

  if (checkbox2) {
    checkbox2.addEventListener('change', () => {
      if (this.checked) {
        fileInput2.required = false;
      } else {
        fileInput2.required = true;
      }
    });
  }

  if (checkbox3) {
    checkbox3.addEventListener('change', () => {
      if (this.checked) {
        fileInput3.required = false;
      } else {
        fileInput3.required = true;
      }
    });
  }
});
