import $ from 'jquery';
import 'select2';

$(document).on('turbo:load', function() {
    $('.select2').select2({
        selectOnClose: true
    });
    $('.select2-dynamic-option').select2({
        selectOnClose: true,
        tags: true
    });
});
