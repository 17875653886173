import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["certificationType", "certifiedEntity"]
  
  connect() {
    this.updateCertifiedEntityField();
  }

  // Certified Entity field management
  updateCertifiedEntityField() {
    const certificationType = document.getElementById('certification_type').value;
    const certifiedEntity = document.getElementById('parcel_import_certified_entity_id');
    const disableCertifiedEntity = certificationType === 'individual' || certificationType === '';

    certifiedEntity.disabled = disableCertifiedEntity;
    if (disableCertifiedEntity) {
      certifiedEntity.value = ''; 
    }
  }
}
