import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["piLine", "certificationType"];

  connect() {
    this.updatePiLineOptions();
    this.updateCertifiedEntityIdField();
    this.observeMutations(); 
  }

  updatePiLineOptions() {
    const selectedPiLineIds = this.piLineTargets
      .filter(select => select.value) 
      .map(select => select.value); 

    this.piLineTargets.forEach(select => {
      Array.from(select.options).forEach(option => {
        option.hidden = selectedPiLineIds.includes(option.value) && select.value !== option.value;
      });
    });
  }

  updateCertifiedEntityIdField() {
    const certificationTypes = document.querySelectorAll('.certification-type');
    const certifiedEntities = document.querySelectorAll('.certified-entity-id');
  
    certificationTypes.forEach((certificationType, index) => {
      const isGrouped = certificationType.value === '1';
      if (certifiedEntities[index]) {
        certifiedEntities[index].disabled = !isGrouped;
      }
    });
  }
  
  observeMutations() {
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(mutations => {
      mutations.forEach(({ addedNodes }) => {
        let shouldUpdatePiLineOptions = false;
        addedNodes.forEach(node => {
          if (node.nodeType === 1 && node.matches('.nested-fields')) {
            node.querySelectorAll('.apply-pi-line-assignments-btn').forEach(btn => {
              btn.style.pointerEvents = 'none';
              btn.style.opacity = '0.5';
            });
            shouldUpdatePiLineOptions = true;
          }
        });
        if (shouldUpdatePiLineOptions) {
          this.updatePiLineOptions();
        }
      });
    });
    observer.observe(this.element, config);
  }  
}
