import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Catalan } from "flatpickr/dist/l10n/cat.js";

export default class extends Controller {
  static values = {
    dayMonth: Boolean,
  };

  connect() {
    this.initializeFlatpickr();
  }

  initializeFlatpickr() {
    const config = {
      altInput: true,
      altFormat: this.dayMonthValue ? "d-F" : "d-F-Y",
      dateFormat: "Y-m-d",
      locale: Catalan,
      onReady: (selectedDates, dateStr, instance) => {
        if (this.dayMonthValue) {
          const yearElements = instance.calendarContainer.querySelectorAll('.flatpickr-year, .numInputWrapper');
          yearElements.forEach(el => el.style.display = 'none');
        }
      },
      allowInput: true,
    };

    flatpickr(this.element, config);
  }
}
