import { Controller } from "@hotwired/stimulus";
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  connect() {
    $(() => {
      setTimeout(() => {
        this.initializeSelect2();
        $('#remote_modal').on('shown.bs.modal', this.initializeSelect2.bind(this));
      }, 10);
    });
  }
  
  disconnect() {
    $('#remote_modal').off('shown.bs.modal', this.initializeSelect2.bind(this));
    this.destroySelect2();
  }

  initializeSelect2() {
    if (!$(this.element).data('select2')) {
      $(this.element).select2(this.select2Options());
    }
  }

  destroySelect2() {
    if ($(this.element).data('select2')) {
      $(this.element).select2('destroy');
    }
  }

  select2Options() {
    const languageOptions = {
      inputTooShort: args => {
        const remainingChars = args.minimum - args.input.length;
        return `Introdueix ${remainingChars} o més caràcters`;
      },
      noResults: () => "No s'han trobat resultats"
    };
  
    const ajaxOptions = {
      url: this.determineUrl(),
      dataType: 'json',
      delay: 250,
      data: function (params) {
        const filterCatalunya = this.element.dataset.filterCatalunya === 'true';
        return {
          q: params.term,
          page: params.page || 1,
          filter_catalunya: filterCatalunya ? 'true' : 'false',
          model: $(this.element).data('model') 
        };
      }.bind(this),
      processResults: function (data, params) {
        params.page = params.page || 1;
  
        return {
          results: data.results,
          pagination: {
            more: data.pagination.more
          }
        };
      },
      cache: true
    };
  
    return {
      minimumInputLength: 1,
      language: languageOptions,
      dropdownParent: $('#remote_modal'),
      ajax: ajaxOptions,
      allowClear: true,
      placeholder: ''
    };
  }  

  determineUrl() {
    const model = $(this.element).data('model');
    switch (model) {
      case 'technician':
        return '/technicians/select2_data';
      case 'municipality':
        return '/municipalities/select2_data';
      case 'elaborator':
      case 'preferredProducerAssociation':
        return '/elaborators/select2_data';
    }
  }
  
}