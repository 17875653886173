import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["certificationType", "certifiedEntity", "piLine"]
  
  connect() {
    this.initializeSelections();
    this.updateCertifiedEntityField();
    this.initializeSurfaceInputs();

    const piLinePlantationField = document.getElementById('pi_line_plantation');
    const newPlantationField = document.getElementById('plantation_new_plantation');

    if (newPlantationField.value == 'true') {
      piLinePlantationField.addEventListener('change', this.populateFields.bind(this));
    }

    this.irrigationElement = document.getElementById('plantation_irrigation');
    this.wateringSystemElement = document.getElementById('plantation_watering_system_id');

    this.toggleWateringSystem();
    this.irrigationElement.addEventListener("change", () => this.toggleWateringSystem());
  }

  // Certified Entity field management
  updateCertifiedEntityField() {
    const certificationType = document.getElementById('certification_type').value;
    const certifiedEntity = document.getElementById('plantation_certified_entity_id');
    const disableCertifiedEntity = certificationType === 'individual' || certificationType === '';

    certifiedEntity.disabled = disableCertifiedEntity;
    if (disableCertifiedEntity) {
      certifiedEntity.value = ''; 
    }
  }

  // Surface calculation functions
  initializeSurfaceInputs() {
    document.querySelector('.add-sigpac').addEventListener('click', () => setTimeout(() => this.initializeSurfaceInputs(), 0));
    
    this.surfaceInputs = Array.from(document.querySelectorAll('.sigpac-surface-input'));
    this.totalSurfaceInput = document.getElementById('plantation-surface');
    
    this.surfaceInputs.forEach(input => input.addEventListener('input', () => this.calculateTotalSurface()));
    document.querySelector('table').addEventListener('click', event => {
      if (event.target.closest('.delete-sigpac')) this.handleDelete(event);
    });
  
    this.calculateTotalSurface();
  }
  
  handleDelete(event) {
    const sigpacEntry = event.target.closest('.delete-sigpac')?.closest('tr.nested-fields.no-labels');
    if (sigpacEntry) {
      const surfaceInput = sigpacEntry.querySelector('.sigpac-surface-input');
      if (surfaceInput) {
        surfaceInput.disabled = true;
      }
      this.calculateTotalSurface();
    }
  }
  
  calculateTotalSurface() {
    this.totalSurfaceInput.value = Array.from(document.querySelectorAll('.sigpac-surface-input'))
      .filter(input => !input.disabled)
      .reduce((sum, input) => sum + parseFloat(input.value || 0), 0)
      .toFixed(2);
  }

  // PI LINE control functions
  initializeSelections() {
    this.initializeFiltering('pi_line_plantation', this.filterSpecies.bind(this));
    this.initializeFiltering('species_plantation', this.filterVarieties.bind(this));
  }

  initializeFiltering(selectId, filterFunction) {
    const selectElement = document.getElementById(selectId);
    if (selectElement?.value) filterFunction({ target: selectElement });
  }

  filterSpecies(event) {
    this.filterOptions(event, 'species_plantation', `/plantations/${this.plantationId}/fetch_species?pi_line_id=`);
  }

  filterVarieties(event) {
    this.filterOptions(event, 'variety_id', `/plantations/${this.plantationId}/fetch_varieties?species_id=`);
  }

  async filterOptions(event, selectId, endpointPrefix) {
    const valueId = event.target.value;
    const targetSelect = document.getElementById(selectId);
    const currentValueId = targetSelect.value;
    const url = `${endpointPrefix}${valueId}`;
  
    const data = await this.updateSelectOptions(url);
    this.resetAndPopulateSelect(targetSelect, data);
    this.handleSelection(targetSelect, currentValueId, selectId === 'species_plantation');
  }

  async updateSelectOptions(url) {
    const response = await fetch(url);
    return await response.json();
  }
  
  resetAndPopulateSelect(select, data) {
    select.innerHTML = '';
    data.forEach(({ id, name }) => select.add(new Option(name, id)));
    select.disabled = data.length === 0;
  }

  handleSelection(select, currentValueId, isSpeciesSelect = false) {
    select.value = select.querySelector(`option[value="${currentValueId}"]`) ? currentValueId : '';
    if (isSpeciesSelect) {
      this.toggleVarietyDisabledState(select.value);
    }
  }

  toggleVarietyDisabledState(speciesValue) {
    const varietySelect = document.getElementById('variety_id');
    varietySelect.disabled = !speciesValue;
    if (!speciesValue) varietySelect.innerHTML = '';
  }

  // watering system control
  toggleWateringSystem() {
    if (this.irrigationElement.checked) {
      this.wateringSystemElement.removeAttribute("disabled");
    } else {
      this.wateringSystemElement.setAttribute("disabled", "disabled");
      this.wateringSystemElement.value = '';
    }
  }

  populateFields(event) {
    const piLinePlantationId = event.target.value;
  
    fetch(`/fetch_user_pi_line_assignments?pi_line_plantation_id=${piLinePlantationId}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          const certifiedEntityField = document.getElementById('plantation_certified_entity_id');
          const technicianField = document.getElementById('plantation_technician_id');
          const certificationTypeField = document.getElementById('certification_type');
  
          certifiedEntityField.value = data.certified_entity_id || '';
          technicianField.value = data.technician_id || '';
          
          if (data.certification_type !== undefined) {
            if (data.certification_type == 0) {
              certificationTypeField.value = 'individual';
              certifiedEntityField.setAttribute('disabled', 'disabled');
            } else {
              certificationTypeField.value = 'grouped';
              certifiedEntityField.removeAttribute('disabled');
            }
          }
  
          if ($(technicianField).data('select2')) {
            $(technicianField).trigger('change');
          }
        }
      });
  }
}