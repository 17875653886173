// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Core libraries
import "@hotwired/turbo-rails"
import "channels"
window.bootstrap = require("bootstrap")

// Stimulus
import "../controllers/index"

// jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Plugins and external libraries
import "@nathanvda/cocoon"
import "datatables.net-bs5"
import "@client-side-validations/client-side-validations/src"
import '@client-side-validations/simple-form/src/index.bootstrap4'

// App-specific javascript
import "../client_side_validations/password_confirmation"
import "../appstack/sidebar"
import "../appstack/user-agent"
import "../appstack/plantation"
import "../pagy/pagy.js.erb"
import "../digital_registries/new"
import "../digital_registries/admin"
import "../select2/select2.js"
import "../digital_registries/file_name"
import "../digital_registries/pi_course"
