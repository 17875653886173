import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["piLinesContainer", "addPILinesButton"];

  connect() {
    this.updateAllSelectOptions();
    this.setupSelectEventListeners();
  }

  addPiLine(event) {
    event.preventDefault();
    const container = this.piLinesContainerTarget;
    const originalRow = container.querySelector('.row');
    const cloneRow = originalRow.cloneNode(true);

    this.resetSelect(cloneRow.querySelector('select'));
    this.addDeleteButton(cloneRow);
    container.appendChild(cloneRow);

    this.refreshUI();
  }

  addDeleteButton(row) {
    const deleteButtonContainer = row.querySelector('.col-1');
    deleteButtonContainer.innerHTML = `
      <button type="button" class="btn btn-light btn-sm add-pi-lines-button" data-action="new-producer#deleteRow">
        <img src="/assets/svg/delete-alt.svg" title="Destroy" class="delete-alt-icon">
      </button>`;
  }

  deleteRow(event) {
    event.preventDefault();
    event.target.closest('.row')?.remove();
    this.refreshUI();
  }

  resetSelect(selectElement) {
    selectElement.value = '';
  }

  updateSelectOptionsVisiblity(selectElement) {
    selectElement.removeEventListener('change', this.selectChangeListener);
  
    const selectedValues = Array.from(this.piLinesContainerTarget.querySelectorAll('select'), select => select.value);
    Array.from(selectElement.options).forEach(option => { 
      option.hidden = selectedValues.includes(option.value) && option.value !== "";
    });
  
    selectElement.addEventListener('change', this.selectChangeListener);
  }

  updateAllSelectOptions() {
    const selects = Array.from(this.piLinesContainerTarget.querySelectorAll('select'));
    const totesSelected = this.getSelectedValues().includes('0');
  
    if (totesSelected) {
      this.handleTotesSelection(selects);
      this.changeAddButtonState(true);
    } else {
      this.changeAddButtonState(false);
    }

    selects.forEach(select => this.updateSelectOptionsVisiblity(select));
  }

  getSelectedValues() {
    return Array.from(this.piLinesContainerTarget.querySelectorAll('select'), select => select.value);
  }

  handleTotesSelection(selects) {
    if (selects[0].value !== '0') {
      selects.forEach(select => select.value === '0' && this.resetSelect(select));
      selects[0].value = '0';
    }
  
    Array.from(this.piLinesContainerTarget.querySelectorAll('.row'))
      .forEach((row, index) => index !== 0 && row.remove());
  }
  
  changeAddButtonState(disable) {
    const button = this.addPILinesButtonTarget;
    button.disabled = disable;
    button.style.pointerEvents = disable ? 'none' : '';
    button.style.opacity = disable ? '0.5' : '1';
  }

  setupSelectEventListeners() {
    this.selectChangeListener = this.updateAllSelectOptions.bind(this);
  
    Array.from(this.piLinesContainerTarget.querySelectorAll('select')).forEach(selectElement => {
      selectElement.removeEventListener('change', this.selectChangeListener);
      selectElement.addEventListener('change', this.selectChangeListener);
    });
  }

  refreshUI() {
    this.setupSelectEventListeners();
    this.updateAllSelectOptions();
  }
}