import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recordType", "operatorType", "filesContainer", "operatorTypeContainer"];

  connect() {
    this.updateOperatorTypeOptions();

    if (this.hasOperatorTypeTarget || this.operatorTypeTarget.value) {
      this.operatorTypeChanged();
    }
  }

  updateOperatorTypeOptions() {
    const selectedRecordType = this.recordTypeTarget.value;
    let optionsToShow;
  
    const allOptions = {
      'Productor': 'Productor',
      'Elaborador': 'Elaborador',
      'Tecnic': 'Tecnic'
    };
    
    if (["1", "2", "3", "5", "6", "7"].includes(selectedRecordType)) {
      optionsToShow = allOptions;
    } else if (selectedRecordType === "4") {
      optionsToShow = { 'Productor': 'Productor', 'Elaborador': 'Elaborador' };
    } else {
      optionsToShow = {};
    }
  
    this.populateOperatorTypeOptions(optionsToShow);
    this.updateOperatorTypeContainerDisplay(Object.keys(optionsToShow).length > 0, selectedRecordType !== "7");
  }
  

  populateOperatorTypeOptions(options) {
    if (this.hasOperatorTypeTarget) {
      const operatorTypeSelect = this.operatorTypeTarget;
      const selectedValue = operatorTypeSelect.value;

      operatorTypeSelect.options.length = 0;

      if (Object.keys(options).length > 0) {
        operatorTypeSelect.add(new Option('', '', true, true));

        Object.entries(options).forEach(([value, text]) => {
          operatorTypeSelect.add(new Option(text, value, value === selectedValue, value === selectedValue));
        });

        operatorTypeSelect.disabled = false;
        this.updateOperatorTypeContainerDisplay(true);
      } else {
        operatorTypeSelect.disabled = true;
        this.updateOperatorTypeContainerDisplay(false);
      }
    }
  }

  updateOperatorTypeContainerDisplay(show, required) {
    if (this.hasOperatorTypeContainerTarget) {
      this.operatorTypeContainerTarget.style.display = show ? '' : 'none';
      const operatorTypeElement = document.getElementById('operator_type');
      operatorTypeElement.required = required;
      if (!required) {
        operatorTypeElement.removeAttribute('required');
      }
    }
  }
  
  operatorTypeChanged() {
    const recordTypeId = this.recordTypeTarget.value;
    const operatorTypeId = this.operatorTypeTarget.value;
    const url = `/digital_registries/update_file_fields?record_type=${recordTypeId}&operator_type=${operatorTypeId}`;

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': 'text/html' }
    }).then(response => response.text())
      .then(html => {
        this.filesContainerTarget.innerHTML = html;
      });
  }
}
